'<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('Effort') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('Survey Name*')" label-for="project_name">
                                                <b-form-select 
                                                v-model="selectedProjectId" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null" 
                                                aria-describedby="input-1-live-feedback" 
                                                :options="projectOptions" 
                                                value-field="id" 
                                                text-field="name"
                                                @change="onProjectChange">
                                                <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                    {{ $t('No Project Found') }}
                                                </b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('survey-style*')" label-for="survey_style_name">
                                                <b-form-input
                                                type="text"
                                                id="survey_style_name"
                                                name="survey_style_name"
                                                :placeholder="$t('survey-style*')"
                                                v-model="form.survey_style_name" 
                                                readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <!--  <div class="col-md-4">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                            <b-form-input
                                                type="text"
                                                id="project_id"
                                                name="project_id"
                                                autocomplete="new-password"
                                                :placeholder="$t('project-id*')"
                                                v-model="form.project_id" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                readonly
                                                >
                                            </b-form-input>
                                            </b-form-group>
                                        </div>-->
                                    </div>
                                    <label for="echoSounders">{{ $t('Use') }}</label>
                                    <div class="row project-checkbox">
                                        <div class="col-lg-8">
                                            <div class="row" v-if="form.survey_style_name !== 'NARW Survey' && form.survey_style_name !== 'Aerial Survey'">
                                                <div class="col-md-4">
                                                    <input type="checkbox" v-model="form.is_scientific_echo_sounder_usable">
                                                    <label for="echoSounders">{{ $t('Scientific Echo-Sounder') }}</label>
                                                </div>
                                                <div class="col-md-5">
                                                    <b-form-group label-for="echoSounders">
                                                        <b-form-select 
                                                        v-model="form.scientific_echo_sounder" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="echoSounders" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="echoSounders.length === 0" :disabled="true">
                                                            {{ $t('No Scientific Echo-Sounder Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('echoSounders')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mt-4">
                                                    <input type="checkbox" v-model="form.is_leg_type_usable">
                                                    <label for="legTypes">{{ $t('Leg Type / Line Type') }}</label>
                                                </div>
                                                <div class="col-md-5 mt-3">
                                                    <b-form-group label-for="legTypes">
                                                        <b-form-select 
                                                        v-model="form.leg_type" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="legTypes" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="legTypes.length === 0" :disabled="true">
                                                            {{ $t('No Leg Type Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('legTypes')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey' || form.survey_style_name === 'Offshore Survey (General)'">
                                                <div class="col-md-4 mt-4">
                                                    <input type="checkbox" v-model="form.is_leg_stage_usable">
                                                    <label for="legStages">{{ $t('Leg Stage') }}</label>
                                                </div>
                                                <div class="col-md-5 mt-3">
                                                    <b-form-group label-for="legStages">
                                                        <b-form-select 
                                                        v-model="form.leg_stage" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="legStages" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="legStages.length === 0" :disabled="true">
                                                            {{ $t('No Leg Stage Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('legStages')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mt-4">
                                                    <input type="checkbox" v-model="form.is_transect_width_usable">
                                                    <label for="transectWidths">{{ $t('Transect Width') }}</label>
                                                </div>
                                                <div class="col-md-5 mt-3">
                                                    <b-form-group label-for="transectWidths">
                                                        <b-form-select 
                                                        v-model="form.transect_width" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="transectWidths" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="transectWidths.length === 0" :disabled="true">
                                                            {{ $t('No Transect Width Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('transectWidths')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mt-4">
                                                    <input type="checkbox" v-model="form.is_survey_mode_usable">
                                                    <label for="surveyModes">{{ $t('Survey Mode') }}</label>
                                                </div>
                                                <div class="col-md-5 mt-3">
                                                    <b-form-group label-for="surveyModes">
                                                        <b-form-select 
                                                        v-model="form.survey_mode" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="surveyModes" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="surveyModes.length === 0" :disabled="true">
                                                            {{ $t('No Survey Mode Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('surveyModes')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 mt-4">
                                                    <input type="checkbox" v-model="form.is_psb_leg_usable">
                                                    <label for="psbLegs">{{ $t('Psb Leg Stage') }}</label>
                                                </div>
                                                <div class="col-md-5 mt-3">
                                                    <b-form-group label-for="psbLegs">
                                                        <b-form-select 
                                                        v-model="form.psb_leg" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="psbLegs" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="psbLegs.length === 0" :disabled="true">
                                                            {{ $t('No Psb Leg Stage Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('psbLegs')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-textarea 
                                                    v-model="inputValue" 
                                                    :placeholder="$t('Enter values separated by commas')"
                                                    rows="5"
                                                    >
                                                    </b-form-textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label for=""><b>{{ $t('Data Entry Fields') }}</b></label>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_leg_number_usable">
                                                            <label for="windSpeed">{{ $t('Leg Number') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey'">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_flight_type_usable">
                                                            <label for="windSpeed">{{ $t('Flight Type') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span>{{ $t('submit-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
         <!-- 
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Efforts') }}</h4>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                            :rows="rows"
                            :columns="columns"
                            @on-search="searchFn"
                            :search-options="{enabled: true,searchFn: searchFn}"
                            :line-numbers="true">
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>-->
        
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, survey_style, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    survey_style_id: '',
                    survey_style_name: '',
                    scientific_echo_sounder:'',
                    leg_type:'',
                    leg_number:'',
                    leg_stage: '',
                    psb_leg: '',
                    effort: '',
                    flight_type: '',
                    quality_left: '',
                    quality_right: '',
                    sun_penetration: '',
                    turbidity: '',
                    transect_width: '',
                    survey_mode: '',
                    wind_speed: '',
                    swell_height: '',
                    visibility: '',
                    is_scientific_echo_sounder_usable: false,
                    is_leg_type_usable: false,
                    is_psb_leg_usable: false,
                    is_leg_number_usable: false,
                    is_leg_stage_usable: false,
                    is_flight_type_usable: false,
                    is_quality_left_usable: false,
                    is_quality_right_usable: false,
                    is_sun_penetration_usable: false,
                    is_transect_width_usable: false,
                    is_survey_mode_usable: false,
                    is_wind_speed_usable: false,
                    is_swell_height_usable: false,
                    is_visibility_usable: false,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'asc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    { label: 'Sighting Number', field: 'sighting_number' },
                    { label: 'Spot ID', field: 'spot_id' },
                    {
                        label: 'Project Name',
                        field: 'project_name',
                        filterable: true
                    },
                    { label: 'Scientific Echo Sounder', field: 'scientific_echo_sounder' },
                    { label: 'Leg Type', field: 'leg_type' },
                    { label: 'Leg Number', field: 'leg_number' },
                    { label: 'Leg Stage', field: 'leg_stage' },
                    { label: 'Flight Type', field: 'flight_type' },
                    { label: 'Transect Width', field: 'transect_width' },
                    { label: 'Survey Mode', field: 'survey_mode' },
                    { label: 'PSB Leg', field: 'psb_leg' },
                    { label: 'Effort', field: 'effort' },
                ],
                isDisabled: false,
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                orders: [],
                projectOptions: [],
                echoSounders: [],
                legTypes: [],
                legStages: [],
                qualityLeft: [],
                qualityRight: [],
                sunPenetration: [],
                turbidity: [],
                transectWidths: [],
                surveyModes: [],
                psbLegs: [],
                efforts: [],
                selectedProjectId: null,
                showInput: false,
                inputValue: '',
                currentField: '',
                
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required,
                },
            },
        },
        mounted() {
            this.loadItems();
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            // this.inputValue = this.echoSoundersName;
            this.getNames();
        },
        methods: {
            toggleInput(field) {
                this.currentField = field;
                // consele
                if (field === 'echoSounders') {
                    this.inputValue = this.echoSoundersName;
                }
                else if(field === 'legTypes'){
                    this.inputValue = this.legTypeNames;
                }
                else if(field === 'legStages'){
                    this.inputValue = this.legStageNames;
                }
                else if(field === 'qualityLeft'){
                    this.inputValue = this.qualityLeftNames;
                }
                else if(field === 'qualityRight'){
                    this.inputValue = this.qualityRightNames;
                }
                else if(field === 'sunPenetration'){
                    this.inputValue = this.sunPenetrationNames;
                }
                else if(field === 'transectWidths'){
                    this.inputValue = this.transectWidthNames;
                }
                else if(field === 'surveyModes'){
                    this.inputValue = this.surveyModeNames;
                }
                else if(field === 'psbLegs'){
                    this.inputValue = this.psbLegsName;
                }
                else if(field === 'efforts'){
                    this.inputValue = this.effortsName;
                }
            },
            onSubmit() {
                if (!this.form.project_id) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                let data = {
                    project_id: this.selectedProjectId,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    is_scientific_echo_sounder_usable : (this.form.survey_style_name === 'Offshore Survey (General)') 
                        ? this.form.is_scientific_echo_sounder_usable 
                        : false,
                    is_leg_type_usable : this.form.is_leg_type_usable,
                    is_leg_number_usable : this.form.is_leg_number_usable,
                    is_leg_stage_usable: (this.form.survey_style_name === 'Aerial Survey' || this.form.survey_style_name === 'Offshore Survey (General)') 
                        ? this.form.is_leg_stage_usable 
                        : false,
                    is_flight_type_usable : this.form.is_flight_type_usable,
                    is_transect_width_usable : this.form.is_transect_width_usable,
                    is_survey_mode_usable : this.form.is_survey_mode_usable,
                    is_psb_leg_usable : this.form.is_psb_leg_usable,
                };
                if (this.currentField === 'echoSounders') {
                    data.scientific_echo_sounder = this.inputValue;
                    console.log('echoSounders', data.echoSounders);
                } 
                else if (this.currentField === 'legTypes') {
                    data.leg_type = this.inputValue;
                } 
                else if (this.currentField === 'legStages') {
                    data.leg_stage = this.inputValue;
                }
                else if (this.currentField === 'transectWidths') {
                    data.transect_width = this.inputValue;
                }
                else if (this.currentField === 'surveyModes') {
                    data.survey_mode = this.inputValue;
                }
                else if (this.currentField === 'psbLegs') {
                    data.psb_leg = this.inputValue;
                }
                API.addEditProjectEffort(
                    data,
                    (data) => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.inputValue = '';
                        this.currentField = '';
                        this.getEffortData(this.selectedProjectId);
                    },
                    // (err) => {
                    //     this.isDisabled = false;
                    //     this.$snotify.error(err, {
                    //         timeout: 2000,
                    //         showProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //     });
                    // }
                );
            },
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                    this.projectOptions = response.data.map(project => ({
                        id: project.id,
                        name: project.project_name
                    }));
                        if (this.projectOptions.length > 0) {
                            this.selectedProjectId = this.projectOptions[0].id;
                            this.getProjectStyleData(this.selectedProjectId);
                            this.getEffortData(this.selectedProjectId);
                            this.toggleInput();
                        }
                    },
                    err => {
                    console.error('Error fetching project names:', err);
                    }
                );
            },
            getProjectStyleData(projectId) {
                API.getProjectSetup(
                    { projectId },
                    response => {
                        const project = response.data;
                        this.form.project_id = project.project_id;
                        this.form.survey_style_id = project.survey_style_id;
                        this.form.survey_style_name = project.survey_style ? project.survey_style.name : '';
                    },
                    err => {
                    console.error('Error fetching project details:', err);
                    }
                );
            },
            getEffortData(projectId) {
                API.getProjectEffortDetails({ projectId }, response => {
                    const projects = response.data;
                    this.echoSounders = [];
                    this.legTypes = [];
                    this.legStages = [];
                    this.transectWidths = [];
                    this.surveyModes = [];
                    this.psbLegs = [];
                    this.efforts = [];

                    if (projects.length > 0) {
                        projects.forEach(project => {
                            const { key, values, is_usable } = project;
                            const valueArray = values ? values.split(',').map(val => val.trim()) : [];

                            switch (key) {
                                case 'scientific_echo_sounder':
                                    this.echoSounders = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_scientific_echo_sounder_usable = !!is_usable;
                                    break;
                                case 'leg_type':
                                    this.legTypes = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_leg_type_usable = !!is_usable;
                                    break;
                                case 'leg_number':
                                    this.form.is_leg_number_usable = !!is_usable;
                                    break;
                                case 'leg_stage':
                                    this.legStages = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_leg_stage_usable = !!is_usable;
                                    break;
                                case 'flight_type':
                                    this.form.is_flight_type_usable = !!is_usable;
                                    break;
                                case 'transect_width':
                                    this.transectWidths = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_transect_width_usable = !!is_usable;
                                    break;
                                case 'survey_mode':
                                    this.surveyModes = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_survey_mode_usable = !!is_usable;
                                    break;
                                case 'psb_leg':
                                    this.psbLegs = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_psb_leg_usable = !!is_usable;
                                    break;
                            }
                        });
                    }
                    else{
                        this.form.is_scientific_echo_sounder_usable = false;
                        this.form.is_leg_type_usable = false;
                        this.form.is_leg_number_usable = false;
                        this.form.is_leg_stage_usable = false;
                        this.form.is_flight_type_usable = false;
                        this.form.is_transect_width_usable = false;
                        this.form.is_survey_mode_usable = false;
                        this.form.is_psb_leg_usable = false;
                    }
                }, err => {
                    console.error('Error fetching project weather details:', err);
                });
            },
            onProjectChange(selectedProjectId) {
                if (selectedProjectId) {
                    this.getProjectStyleData(selectedProjectId);
                    this.getEffortData(selectedProjectId);
                    this.toggleInput();
                }
            },
            loadItems() {
                this.isLoading = true;
                console.log('Fetching Efforts Data with params:', this.serverParams);
                API.getEfforts(this.serverParams,
                    data => {
                        console.log('Fetched Data:', data);
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data;
                        console.log('Rows:', this.rows);
                    },
                    err => {
                        this.isLoading = false;
                        console.error('Error fetching data:', err);
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            status(status) {
                if (status === 'A') {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        }, 
        computed: {
            echoSoundersName: {
                get() {
                    return this.echoSounders.map(code => code.name).join(', ');
                },
                set(newValue) {
                    const echoSounders = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.echoSounders.find(code => code.name === name.trim()).id };
                    });
                    this.form.scientific_echo_sounder = echoSounders;
                }
            },
            legTypeNames: {
                get() {
                    return this.legTypes.map(leg_type => leg_type.name).join(', ');
                },
                set(newValue) {
                    const legTypes = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.legTypes.find(leg_type => leg_type.name === name.trim()).id };
                    });
                    this.form.leg_type = legTypes;
                }
            },
            legStageNames: {
                get() {
                    return this.legStages.map(cloud => cloud.name).join(', ');
                },
                set(newValue) {
                    const legStages = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.legStages.find(cloud => cloud.name === name.trim()).id };
                    });
                    this.form.leg_stage = legStages;
                }
            },
            psbLegsName: {
                get() {
                    return this.psbLegs.map(cloud => cloud.name).join(', ');
                },
                set(newValue) {
                    const psbLegs = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.psbLegs.find(cloud => cloud.name === name.trim()).id };
                    });
                    this.form.psb_leg = psbLegs;
                }
            },
            effortsName: {
                get() {
                    return this.efforts.map(cloud => cloud.name).join(', ');
                },
                set(newValue) {
                    const efforts = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.efforts.find(cloud => cloud.name === name.trim()).id };
                    });
                    this.form.effort = efforts;
                }
            },
            qualityLeftNames: {
                get() {
                    return this.qualityLeft.map(quality => quality.name).join(', ');
                },
                set(newValue) {
                    const qualityLeft = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.qualityLeft.find(quality => quality.name === name.trim()).id };
                    });
                    this.form.quality_left = qualityLeft;
                }
            },
            qualityRightNames: {
                get() {
                    return this.qualityRight.map(quality => quality.name).join(', ');
                },
                set(newValue) {
                    const qualityRight = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.qualityRight.find(quality => quality.name === name.trim()).id };
                    });
                    this.form.quality_right = qualityRight;
                }
            },
            sunPenetrationNames: {
                get() {
                    return this.sunPenetration.map(penetration => penetration.name).join(', ');
                },
                set(newValue) {
                    const sunPenetration = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.sunPenetration.find(penetration => penetration.name === name.trim()).id };
                    });
                    this.form.sun_penetration = sunPenetration;
                }
            },
            transectWidthNames: {
                get() {
                    return this.transectWidths.map(w => w.name).join(', ');
                },
                set(newValue) {
                    const transectWidths = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.transectWidths.find(w => w.name === name.trim()).id };
                    });
                    this.form.transect_width = transectWidths;
                }
            },
            surveyModeNames: {
                get() {
                    return this.surveyModes.map(w => w.name).join(', ');
                },
                set(newValue) {
                    const surveyModes = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.surveyModes.find(w => w.name === name.trim()).id };
                    });
                    this.form.survey_mode = surveyModes;
                }
            },

        },
    }
</script>

<style>
  .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    .checkbox-radio {
        display: flex;
        gap: 35px;
    }
    .checkbox-radio label {
        padding-left: 7px;
    }
    .card-body.new-card-body select {
        border: 1px solid #000;
        border-radius: 0;
        height: 46px;
    }
    .project-checkbox label {
        padding-left: 15px;
    }
    .project-checkbox textarea {
        border: 1px solid #000 !important;
        height: 250px;
        outline: none;
        box-shadow: none;
        border-radius: 0;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
